.d-flex{
  display: flex;
}

.align-items-center{
  align-items: center;
}

.justify-content-center{
  justify-content: center;
}

.justify-content-between{
  justify-content: space-between;
}

.align-items-end{
  align-items: flex-end;
}

.flex-column{
  flex-direction: column;
}

.h-100{
  height: 100%;
}

.mb-4{
  margin-bottom: 24px;
}

.mb-5{
  margin-bottom: 48px;
}

.pl-0{
  padding-left: 0 !important;
}

.ml-0{
  margin-left: 0 !important;
}

.panel-sub{
  position: relative;
  .panel-nested{
    width: 100%;
    display: block;
    
  }
}

.panel-group{
  a{
    color: #333;
    font-size: 16px;
  }
  .list-nested{
    li{
      list-style: none;
      font-size: 16px;
    }
  }
}

.panel-sub a[aria-expanded=false]:before {
  content: "\e114"; 
  position: absolute;
  right: 0;
  font-family: 'Glyphicons Halflings';
  color: #333;
  transform: rotate(0);
  transition: all 0.5s;
}
.panel-sub a[aria-expanded=true]:before {
  content: "\e114"; 
  position: absolute;
  right: 0;
  font-family: 'Glyphicons Halflings';
  color: #333;
  transform: rotate(180deg);
  transition: all 0.5s;
}

.megamenu{  
  display: none;
  position: absolute;
  background: #fff;
  z-index: 1;
  top: 60px;
  width: 205px;
  margin-left: -25px;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px  rgba(0, 0, 0, 0.1);
  .megamenu-right{
    position: absolute;
    right: -202px;
    top: 0px;
    width: 200px;
    background: #fff;
    box-shadow: 1px 1px 1px 1px  rgba(0, 0, 0, 0.1);
    display: none;
  }
  .cols{
    padding: 0 !important;
    ul{
      text-align: left;
      li{
        &:hover{
          .megamenu-right{
            display: block;
          }
        }
        a{
          padding: 10px 20px;
          position: relative;
          .fa{
            position: absolute;
            right: 15px;
            top: 15px;
          }

        }
      }
    }
    .cols-left{
      display: block !important;
      li{
        a{
          padding: 10px 20px !important;
        }
      }
    }
  }
}


.weekly-wrapper{
  .top-column{
    padding: 50px 0 24px;
    h4{
      font-weight: 700;
    }
  }
  .weekly-content-wrapper{
    font-family: 'Roboto', sans-serif;
    .block{
      border: 1px solid #ede6e6;
      border-radius: 0 5px 5px 0;
      overflow: hidden;
      @media screen and (max-width:767px) {
        border-radius: 5px;
      }
      .img{
        @media screen and (max-width:767px) {
          height: 200px;
          overflow: hidden;
        }
        img{
          @media screen and (max-width:767px) {
            width: 100%;
            object-fit: cover;
            height: 200px;
          }
          @media screen and (min-width:768px) {
            height: 290px;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .column{
        padding: 10px 15px 10px 0px;
        color: #6C6C6C;
        @media screen and (max-width:768px) {
          padding: 10px;
        }
        .cat{
          color: #B38E51;
        }
        .title-block{
          font-weight: 500;
          font-size: 18px;
          font-family: 'Roboto', sans-serif;
          a{
            color: #000;
            text-decoration: none;
            &:hover{
              color: #6C6C6C;
            }
          }
        }
        .shortdesc{
          font-size: 14px;
          @media screen and (min-width:991px) {
            min-height: 150px;
          }
        }
        .download-pdf{
          font-size: 16px;
          img{
            padding-right: 8px;
          }
        }
      }
    }
  }
}

.d-none{
  display: none !important;
}